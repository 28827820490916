import React from "react";
import SpeakerImg from "../../assets/images/speaker-img.webp";
import S1 from "../../assets/images/s1.webp";
import S2 from "../../assets/images/s2.webp";
import S3 from "../../assets/images/s3.webp";
import S4 from "../../assets/images/s4.webp";
import S5 from "../../assets/images/s5.webp";
import S6 from "../../assets/images/s6.webp";
import S7 from "../../assets/images/s7.webp";
import S8 from "../../assets/images/s8.webp";
import S9 from "../../assets/images/s9.webp";
import S10 from "../../assets/images/s10.webp";
import S11 from "../../assets/images/s11.webp";
import S12 from "../../assets/images/s12.webp";
import S13 from "../../assets/images/s13.webp";
import S14 from "../../assets/images/s14.webp";
import S15 from "../../assets/images/s15.webp";
import S16 from "../../assets/images/s16.webp";
import S17 from "../../assets/images/s17.webp";
import S18 from "../../assets/images/s18.webp";
import S19 from "../../assets/images/s19.webp";
import S20 from "../../assets/images/s20.webp";
import S21 from "../../assets/images/s21.webp";
import S22 from "../../assets/images/s22.webp";
import S23 from "../../assets/images/s23.webp";
import S24 from "../../assets/images/s24.webp";
import S25 from "../../assets/images/s25.webp";
import S26 from "../../assets/images/s26.webp";
import S27 from "../../assets/images/s27.webp";
import S28 from "../../assets/images/s28.webp";
import S29 from "../../assets/images/s29.webp";
import O1 from "../../assets/images/o1.webp";
import O2 from "../../assets/images/o2.webp";
import O3 from "../../assets/images/o3.webp";

export const SpeakersContent = () => {

  return (
    <div>
      <div className="organizers">
        <div className="mt-5">
          <div className="container ">
            <div className="stroke-text-container d-flex justify-content-center">
              <h1 className="bg-text-stroke">Organisers</h1>
              <h1 className="gradient-text-heading">Organisers</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="organizer-card">
                <div>
                  <img className="w-100 h-auto" src={O1} alt=""></img>
                </div>
                <div className="organizer-social-media">
                  <div className="speaker-flex">
                    <div className="speaker-sm-icon">
                      <a
                        href="https://www.facebook.com/dharaneetharangd/"
                        className="sm-icon"
                      >
                        <i class="fa-brands fa-facebook-f"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/results?search_query=dharaneedharan"
                        target="_blank"
                        className="sm-icon"
                      >
                        <i class="fa-brands fa-youtube"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/dharaneetharan/?originalSubdomain=in"
                        target="_blank"
                        className="sm-icon"
                      >
                        <i class="fa-brands fa-linkedin"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/dharaneetharan/?hl=en"
                        target="_blank"
                        className="sm-icon"
                      >
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </div>
                    <p className="speaker-white-text">410K+ followers</p>
                  </div>
                </div>
                <div className="speaker-content-bg">
                  <h5 className="speaker-name">G D Dharaneetharan</h5>
                  <p className="speaker-job">
                    Founder - Social Eagle & 21DC Community
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="organizer-card">
                <div>
                  <img className="w-100 h-auto" src={O2} alt=""></img>
                </div>
                <div className="organizer-social-media">
                  <div className="speaker-flex">
                    <div className="speaker-sm-icon">
                      <a
                        href="https://www.facebook.com/sudharsananganapathy"
                        target="_blank"
                        className="sm-icon"
                      >
                        <i class="fa-brands fa-facebook-f"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/results?search_query=sudharsanan+ganapathy"
                        target="_blank"
                        className="sm-icon"
                      >
                        <i class="fa-brands fa-youtube"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/sudharsananganapathy/?originalSubdomain=in"
                        target="_blank"
                        className="sm-icon"
                      >
                        <i class="fa-brands fa-linkedin"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/sudharsananganapathy/?hl=en"
                        target="_blank"
                        className="sm-icon"
                      >
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </div>
                    <p className="speaker-white-text">500K+ followers</p>
                  </div>
                </div>
                <div className="speaker-content-bg">
                  <h5 className="speaker-name">Sudharsanan Ganapathy</h5>
                  <p className="speaker-job">Founder - The Social Company</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="organizer-card">
                <div>
                  <img className="w-100 h-auto" src={O3} alt=""></img>
                </div>
                <div className="organizer-social-media">
                  <div className="speaker-flex">
                    <div className="speaker-sm-icon">
                      <a
                        href="https://www.facebook.com/imvishnuhari?mibextid=ZbWKwL"
                        target="_blank"
                        className="sm-icon"
                      >
                        <i class="fa-brands fa-facebook-f"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/vishnuhari-harikumar/"
                        target="_blank"
                        className="sm-icon"
                      >
                        <i class="fa-brands fa-linkedin"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/imvishnuhari/"
                        target="_blank"
                        className="sm-icon"
                      >
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </div>
                    <p className="speaker-white-text">10K+ followers</p>
                  </div>
                </div>
                <div className="speaker-content-bg">
                  <h5 className="speaker-name">Vishnuhari</h5>
                  <p className="speaker-job">Co-Founder - Social Eagle</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="container ">
          <div className="stroke-text-container d-flex justify-content-center">
            <h1 className="bg-text-stroke">Speakers</h1>
            <h1 className="gradient-text-heading">Speakers Line-Up</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/search/top?q=ck%20kumaravel"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    {/* <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-youtube"></i></a> */}
                    <a
                      href="https://www.linkedin.com/in/ckknaturals/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/ckkumaravel/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">37K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S9} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">C K Kumaravel</h6>
                <p className="speaker-job">Co-Founder - Naturals</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Co- founder of India’s leading Salon chain with over 700+
                    salons with 23+ years of Market Dominance.{" "}
                  </p>
                  <p className="speaker-data">
                    Empowering women to become entrepreneurs to create a
                    housewife-free India.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    {/* <a
                      href="https://www.facebook.com/search/top?q=ck%20kumaravel"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-youtube"></i></a>
                    <a
                      href="https://www.linkedin.com/in/ckknaturals/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/ckkumaravel/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a> */}
                  </div>
                  <p className="speaker-white-text">1K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S23} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Muthuraman</h6>
                <p className="speaker-job">Founder - Lakshmi Ceramics</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Learn how to become “The Unstoppable Force (Preparing Yourself For The Now & Beyond)" from the Founder of leading name in ceramic tiles and related products.
                  </p>
                  <p className="speaker-data  mb-2">
                    Earning numerous awards and accolades for excellence and innovation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/velumania1/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/drvelumani/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">1.2M+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S28} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Dr. Velumani</h6>
                <p className="speaker-job">Founder of Thyrocare Technologies</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Revolutionized India's diagnostic industry by making healthcare affordable and high-quality.
                  </p>
                  <p className="speaker-data  mb-0">
                    His dedication to innovation has made Thyrocare a trusted name in healthcare.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/ramanathan.sivarajah/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/nativelead_siva/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">65K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S29} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Shivaraja Ramanathan</h6>
                <p className="speaker-job">Mission Director and CEO of StartupTN</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Sivarajah Ramanathan spearheads Tamil Nadu's startup revolution as the Mission Director and CEO of StartupTN, Leveraging his 10-year background as a founder and CEO of a successful angel network
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/dharaneetharangd/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/results?search_query=dharaneedharan"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/dharaneetharan/?originalSubdomain=in"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/dharaneetharan/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">410K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S17} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">G D Dharaneetharan</h6>
                <p className="speaker-job">Founder & CEO - Social Eagle</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Purpose Driven Entrepreneur with over 400K+ Followers across
                    all social media platforms.{" "}
                  </p>
                  <p className="speaker-data  mb-2">
                    Impacted 20000+ people with his 5 AM 21 days challenge
                    program.
                  </p>
                  <p className="speaker-data">
                    Learn the practical framework of how to become a Crorepati
                    in 2024.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* seyal */}
          {/* <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/Venkatesh.sysmantech"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>

                    <a href="https://www.linkedin.com/in/narayanaswamyvenkatesh/?originalSubdomain=in" target="_blank" className="sm-icon"><i class="fa-brands fa-linkedin"></i></a>
                    <a
                      href="https://www.instagram.com/venkatesh_narayansamy/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">12.1K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S25} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Venkatesh Narayanasamy</h6>
                <p className="speaker-job">Entrepreneur, Leader, Community Advocate</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Committed to making a positive impact in business and society.
                  </p>
                  <p className="speaker-data">
                    Passionate about entrepreneurship, leadership, and helping others.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/drsanthoshjacob"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@DrSanthoshJacob"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/dr-santhosh-jacob-15965572/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/drsanthoshjacob/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">1.8M+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S8} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Dr. Santhosh Jacob</h6>
                <p className="speaker-job">Orthopaedic & Sports Surgeon</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Impacting 1.8M+ people across all Social Media.{" "}
                  </p>
                  <p className="speaker-data  mb-2">
                    Creating Content with passion to simplify Medicine and
                    lifestyle to Patients.{" "}
                  </p>
                  <p className="speaker-data">
                    Learn why Content creation is crucial for growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/sudharsananganapathy"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/results?search_query=sudharsanan+ganapathy"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/sudharsananganapathy/?originalSubdomain=in"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/sudharsananganapathy/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">500K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S18} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Sudharsanan Ganapathy</h6>
                <p className="speaker-job">Founder - The Social Company</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Runs India’s #1 Personal Branding Company Learn the Power of
                    personal branding now and beyond.{" "}
                  </p>
                  <p className="speaker-data">
                    Coached & Trained 50,000+ People and Worked with 300
                    Entrepreneurs & CEOs
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/deepak.biz"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@highdeepak"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/deepakkanakaraju/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/deepak.kanakaraju?igsh=bDR3anA5cm8zcWF6"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">202K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S2} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Digital Deepak</h6>
                <p className="speaker-job">Co-Founder - Pixeltrack</p>
                <div className="">
                  <p className="speaker-data  mb-2">
                    OG marketer & has one of the biggest email lists in this
                    country.
                  </p>
                  <p className="speaker-data">
                    Creator of DEEP Method of Marketing to attract strangers
                    into customers and build more authority.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/profile.php?id=100011235127440"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@UAbility"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/rohandhawan/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/wegiveuability/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">18K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S1} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Rohan Dhawan</h6>
                <p className="speaker-job">Founder - Uability</p>

                <div className="">
                  <p className="speaker-data mb-2">
                    Clocking over 1.5 Crores/mo in online sales with a massive
                    profit ratio.
                  </p>
                  <p className="speaker-data">
                    Learn how to Charge high Prices regardless of experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/spratham894"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    {/* <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-youtube"></i></a> */}
                    <a
                      href="https://www.linkedin.com/in/pratham-sharma-9707b0b7/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/first.maverick/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">11K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S13} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Pratham Sharma</h6>
                <p className="speaker-job">Principal Consultant at Uability</p>

                <div className="">
                  <p className="speaker-data mb-2">
                    5,000+ sales calls with a mind blowing 25%+ sales
                    conversions on a HIGH TICKET price!
                  </p>
                  <p className="speaker-data">
                    {" "}
                    Learn about his 10 Crores sales strategies & how you can
                    replicate the same.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/gopalkrishnan.g"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@coachgopalkrishnan"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/gopal-krishnan-003a008/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/unicorncoachgopal/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">43K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S7} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Gopal Krishnan</h6>
                <p className="speaker-job">Creator of Binge Marketing Summit</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    He teaches coaches to build their leadership brands and scale to seven figures via WhatsApp classes,
                    using his Binge Marketing Model that has boosted leads, sales,
                    and community engagement for over 25,000 entrepreneurs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/sakthivel.pannerselvam.surprise"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCOTyLXuouTNWZKlQFqPDaJA"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/sakthivelpannerselvam/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/sakthivelpannerselvam6/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">225K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S24} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Sakthivel Pannerselvam</h6>
                <p className="speaker-job">Guerrilla Marketing Expert - Chief Surprise Ninja</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    An author, Guerilla marketer, experience curator and “Chief Surprise Ninja”.
                  </p>
                  <p className="speaker-data">
                    Helping people in making ordinary moments into extraordinary experiences with Guerilla Marketing.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/abubacker5020/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://youtube.com/@timebillionaireabu1993?si=7gZufGfjhhxhiMqR"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/mohmed-abubacker-samsudeen-254512148/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/timebillionaireabu/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">1M+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S16} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Mohmed AbuBacker Samsudeen </h6>
                <p className="speaker-job">
                  Founder at Traderoom & Co-founder at Tint Tone & Shade
                </p>

                <div className="">
                  <p className="speaker-data mb-2">
                    Gained 1 M followers in 12 months.
                  </p>
                  <p className="speaker-data">
                    Business coach to inspire and empower others through online
                    platforms.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.youtube.com/@NeerajElango"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/neeraj-elangovan-5379b2213/
                      "
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/neeraj_elango?igsh=d2YzMjZ2NWc1dDlw"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">540K + followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S22} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Neeraj Elangovan</h6>
                <p className="speaker-job">
                  Founder : Uneevue Studios (OPC) PVT. LTD
                </p>

                <div className="">
                  <p className="speaker-data mb-2">
                    Leveraging Visual storytelling to showcase products and
                    food.
                  </p>{" "}
                  <p className="speaker-data">
                    Helping businesses with high quality video requirements
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    {/* <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-facebook-f"></i></a> */}
                    <a
                      href="https://www.youtube.com/@theagneljohn_"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/theagneljohn/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/theagneljohn/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">1.5M followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S5} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Agnel John</h6>
                <p className="speaker-job">Founder - Error Makes Clever</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    1.5M + in all social platforms for his brand and his
                    personal account.{" "}
                  </p>
                  <p className="speaker-data">
                    TED X Speaker, Content Creator & helping students to enhance
                    their skills in technology & mindset.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/Stylewithmc"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://youtube.com/@Manochandru.?si=569uEqvn47mEMhl-"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/mano-chandru-4b70b8240/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/thetech_indian/?igsh=MTd1emZ5cGl1d3QxZg%3D%3D&utm_source=qr"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">300K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S6} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Mano Chandru</h6>
                <p className="speaker-job">Founder - Clevercut</p>

                <div className="">
                  <p className="speaker-data">
                    Video editor, entrepreneur, and content creator with more
                    than 50K+ Following across all social Media.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/imvishnuhari?mibextid=ZbWKwL"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    {/* <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-youtube"></i></a> */}
                    <a
                      href="https://www.linkedin.com/in/vishnuhari-harikumar/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/imvishnuhari/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">10K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S19} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Vishnu Hari</h6>
                <p className="speaker-job">Co-founder - Social Eagle</p>
                <div className="">
                  <p className="speaker-data  mb-2">
                    Total $3 Million in Ad spends for clients profitably.{" "}
                  </p>
                  <p className="speaker-data  mb-2">
                    Helped brands to achieve $1M in sales using Performance
                    Marketing.{" "}
                  </p>
                  <p className="speaker-data">
                    Learn How to leverage AI in Marketing.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/NivethaNewgen"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@Newgenmax"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/nivethamuralidharan/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/nivetha_muralidharan/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">112.3K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S10} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Nivetha Muralidharan</h6>
                <p className="speaker-job">
                  Founder - Newgenmax Digital Solutions
                </p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    10+ years of experience in Ecommerce space.
                  </p>
                  <p className="speaker-data">
                    Leveraging Amazon to its fullest.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    {/* <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-facebook-f"></i></a> */}
                    <a
                      href="https://www.youtube.com/@NEIDHALONLINE"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    {/* <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-linkedin"></i></a> */}
                    <a
                      href="https://www.instagram.com/neidhalrajkumar/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">206.1K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S12} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Rajkumar</h6>
                <p className="speaker-job">Founder - Neidhal.com</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    10+ yrs of Experience in D2C selling.{" "}
                  </p>
                  <p className="speaker-data">
                    Leveraging Social Platforms to sell at least 100+ products a
                    day without spending on Ads.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a href="https://www.facebook.com/share/byUbsTc3aodJPtwY/?mibextid=LQQJ4d" target="_blank" className="sm-icon"><i class="fa-brands fa-facebook-f"></i></a>
                    <a
                      href="https://youtube.com/@habbadafashions9669?feature=shared"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/habbada_fashions?igsh=aGZsdnE3dGRpczJ6&utm_source=qr"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">12.5K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S26} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Nithya G</h6>
                <p className="speaker-job">Founder - Habbada Fashions</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Learn her transition from corporate to entrepreneurship, understanding customer needs, designing for comfort, and inclusive fashion.
                  </p>
                  <p className="speaker-data">
                    Habbada Fashions grew from 8 lakhs in 6 months to 85 lakhs in 2023, now making 8-10 lakhs in revenue.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    {/* <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-facebook-f"></i></a>
                                        <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-youtube"></i></a>
                                        <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-linkedin"></i></a>
                                        <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-instagram"></i></a> */}
                  </div>
                  <p className="speaker-white-text">1K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S11} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Vijay S Raja</h6>
                <p className="speaker-job">Co-Founder - Three Dots</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    His brands clocked 6Cr + Collectively.
                  </p>
                  <p className="speaker-data">
                    Leveraging the marketplace to the Max.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/profile.php?id=100086345384809"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@Khanthini_Psychologist"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/khanthini-vijayakumar-52582b2a7/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/khanthini_psychologist/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">273K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S20} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Khanthini Vijayakumar</h6>
                <p className="speaker-job">Psychologist</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Went from 0 - 270 K+ followers in 90 days.{" "}
                  </p>
                  <p className="speaker-data">4M views in 30 days. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/profile.php?id=61554920368146"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@MaalicaRavikumar"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/maalica-ravikumar-952539244/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/maalica.ravikumar/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">133K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S21} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Maalica Ravikumar</h6>
                <p className="speaker-job">
                  Life Coach & Founder - Evergrowthacademy
                </p>

                <div className="">
                  <p className="speaker-data">
                    3.2M views in last 30 days Leading Life Coach trusted by
                    20K+ Women.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/profile.php?id=61554971965769"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@jayanthirameshkumar3770"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/jayanthi-rameshkumar-286654b1/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/jayanthi_rameshkumar/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">30K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S3} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Jayanthi Rameshkumar</h6>
                <p className="speaker-job">
                  Founder - Smartkids Pregnancy Caring Centre
                </p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Supported 6000+ Moms through pregnancy & lactation Support.{" "}
                  </p>
                  <p className="speaker-data">
                    More than 1.1M + Accounts reached in less than 4 Weeks.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/profile.php?id=61555273556711
                      "
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@drsaravananarunachalam"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    {/* <a
                      href="https://www.linkedin.com/in/jayanthi-rameshkumar-286654b1/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a> */}
                    <a
                      href="https://www.instagram.com/dr.saravanan.arunachalam_rkp/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">20K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S4} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Dr.Saravanan Arunachalam</h6>
                <p className="speaker-job">Pediatrician - RKP Hospital</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    10+ Years of Helping parents to supercharge their kid’s
                    health.
                  </p>
                  <p className="speaker-data">
                    Changing passion into profits by building authority online.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*
          TNDS
          */}
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/Venkatesh.sysmantech"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>

                    <a href="https://www.linkedin.com/in/narayanaswamyvenkatesh/?originalSubdomain=in" target="_blank" className="sm-icon"><i class="fa-brands fa-linkedin"></i></a>
                    <a
                      href="https://www.instagram.com/venkatesh_narayansamy/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">12.1K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S25} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Venkatesh Narayanasamy</h6>
                <p className="speaker-job">Entrepreneur, Leader, Community Advocate</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Committed to making a positive impact in business and society.
                  </p>
                  <p className="speaker-data">
                    Passionate about entrepreneurship, leadership, and helping others.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    {/* <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-facebook-f"></i></a> */}
                    <a
                      href="https://www.youtube.com/@shubhhjain21"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    {/* <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-linkedin"></i></a> */}
                    <a
                      href="https://www.instagram.com/shubhhjain/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  {/* <p className="speaker-white-text">410K+ followers</p> */}
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S14} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Shubh Jain</h6>
                <p className="speaker-job">Founder - Launch at scale</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Over $7M in sales for his clients and has set multiple
                    records.{" "}
                  </p>
                  <p className="speaker-data">
                    {" "}
                    Learn about Persuasion, make huge people buy whatever you’re
                    selling.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    <a
                      href="https://www.facebook.com/therealswastik"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@swastiknandakumar"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                    {/* <a href="" target="_blank" className="sm-icon"><i class="fa-brands fa-linkedin"></i></a> */}
                    <a
                      href="https://www.instagram.com/swastiknandakumar/?hl=en"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">34K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S15} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Swastik Nandakumar</h6>
                <p className="speaker-job">Peak Performance Coach</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    India’s #1 Peak Performance Coach transforming 15000+
                    people.{" "}
                  </p>
                  <p className="speaker-data">
                    Learn how to master communication, transforming identities &
                    RESETing your brain for success.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="speaker-card">
              <div className="speaker-social-media">
                <div className="speaker-flex">
                  <div className="speaker-sm-icon">
                    {/* <a
                      href="https://www.facebook.com/therealswastik"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@swastiknandakumar"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a> */}
                    <a href="https://www.linkedin.com/in/karthik-ramani-53275716/" target="_blank" className="sm-icon"><i class="fa-brands fa-linkedin"></i></a>
                    <a
                      href="https://www.instagram.com/fleximarketer/"
                      target="_blank"
                      className="sm-icon"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <p className="speaker-white-text">3K+ followers</p>
                </div>
              </div>
              <div>
                <img className="w-100 h-auto" src={S27} alt=""></img>
              </div>
              <div className="speaker-content-bg speaker-content-height">
                <h6 className="speaker-name">Karthik Ramani</h6>
                <p className="speaker-job">Co-Founder, Flexifunnels</p>

                <div className="">
                  <p className="speaker-data  mb-2">
                    Helping people start their entrepreneurial journey using High Converting funnels and access to a buyers Email list of over 200k+.
                  </p>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
